@use "../../../styles/lib/" as lib;

.sidenav {
  height: 100vh; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 201; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: lib.$primary; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  // padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  // padding-left: 1em;
  .sidenav-header-wrapper {
    position: relative;
    padding-top: 0.15em;
    z-index: 202;
    width: 100vw;
    height: 7vh;

    .header {
      width:100%;
      height: 100%;
      background: lib.$primary;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-left: 1em;

      padding: 0 1em;
      /* Position and style the close button (top right corner) */
      .closebtn {
        // background-color: lib.$white;
        // opacity:0.7;
        // border-radius: 16px;
        // color:white;
        // position: absolute;
        // top: 0;
        // right: 25px;
        // padding: 0 0.5em;
        // height:36px;
        // width:36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5em;
        // font-size: 24px;
        height:32px;
        width:32px;
        cursor: pointer;
        // font-size:2em;
        // margin-left: 50px;
      }
    }
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    background: lib.$primary;
    text-align: start;

    .nav-list-item {
      .active{
      color: lib.$grey;
      pointer-events: none;
    }
      background-color: lib.$primary;

      /* The navigation menu links */
      > a {
        //   padding: 8px 8px 8px 32px;
        text-decoration: none;
        //   font-size: 25px;
        font-weight: 700;
        color: lib.$white;
        display: block;
        transition: 0.3s;
        //   width: 40%;
        //   background: magenta;
        margin-left: 2rem;
        padding-left: 0;
        padding: 1em 0;
        //   text-align: start;
        //   float: left;
      }

      .social-media {
        border-top: 2px solid white;
        height: 100%;
        width: 80%;
        // background:pink;
        margin-left: 2rem;
        padding: 1em 0;
        margin-top: 1em;
        display: flex;
        flex-direction: column;

        > #side-menu-logos {
          display: flex;
          // justify-content: space-around;
          align-items: start;
          gap: 20px;
          width: 40%;
          padding: 0.5em 0;
        }

        > #side-menu-email {
          color: white;
          padding: 0.5em 0;
          font-size: 0.7em;
        }
      }
    }

    /* When you mouse over the navigation links, change their color */
    a:hover {
      color: lib.$grey;
    }
  }
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  // .sidenav {
  //   //   padding-top: 15px;
  // }
  .sidenav .nav-list a {
    font-size: 18px;
  }
}

@media (min-width:1025px){
  .sidenav {
  height: 100vh; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 201; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: lib.$primary; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  // padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  // padding-left: 1em;
  margin:0;
  padding:0;

  .sidenav-header-wrapper {
    position: relative;
    padding-top: 0;
    z-index: 202;
    width: 100vw;
    height: 7vh;

    .header {
      width: 30vw;
      // height: 100%;
      background: lib.$primary;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-left: 1em;

      padding: 0 1em;
      /* Position and style the close button (top right corner) */
      .closebtn {
        // background-color: lib.$white;
        // opacity:0.7;
        // border-radius: 16px;
        // color:white;
        // position: absolute;
        // top: 0;
        // right: 25px;
        // padding: 0 0.5em;
        // height:36px;
        // width:36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5em;
        // font-size: 24px;
        height:32px;
        width:32px;
        cursor: pointer;
        // font-size:2em;
        // margin-left: 50px;
      }
    }

    
  }

  .nav-list {
    .nav-list-item {
      > a {
          font-size: 1.1em;
      }
      .social-media {
        > #side-menu-email {
          font-size: 1em;
        }
      }
    }
    a:hover {
      color: lib.$grey;
    }
  }
}
}

// @media only screen and (min-width: 1025) {
//   .sidenav{
//     .header{
//       width: 40vw;
//     }
//   }
// }