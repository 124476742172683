@use '../../../styles/lib' as lib;

.video-player-wrapper {
  position: absolute;
  aspect-ratio: 16/9;
  // height: 100vh;
  // width:100vw;
  // max-height:100%;
  // height: 70%;
  // width:70%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index:100;
  background-color: black;
  overflow:hidden;

  // &.fullscreen {
  //   max-height:100vh;
  //   max-width:100vw;
  //   z-index: 150;
  // }

}

.player{
  // opacity: 0.5;
  width: 100vw;
  // height: 100vh;
  position: relative;
  margin: auto;
  // top:50%;
  // transform:translate(0,-50%)
  // bottom:auto;

}

.ios-player{
  width: 100vw;
  position: relative;
}

.ios-control-board{
  // width: 15%;
  // height: 15%;
  position:absolute;
  z-index: 200;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);

}

.control-board {
  width: 100%;
  height: 15%;
  position: absolute;
  z-index: 200;
  background: transparent;
  opacity: 0.5;
  bottom: 0;
  display:grid;
  grid-template-columns: 10% auto 10% 10%;
  grid-template-rows: auto;

  #btn-play , #btn-fullscreen, #btn-mute{
        display: flex;
        justify-content: center;
        align-items: center;
        *{
          display: block;
          // height:100%;
          // width:10%;
        }
      }
    
      #timeline {
        width:100%;
      }
}

.m-interactor{
  background:transparent;
  position:absolute;
  top:0;
  left:0;
  height:85%;
  width:100%;
  z-index: 190;
}

@media (orientation:portrait){
  .video-player-wrapper{
    height:27%;
  }
}

@media screen and (min-width:768px){
  .control-board{
    bottom:5%;
  }
}