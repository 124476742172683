@font-face {
 font-family: 'Founder';
 src: url(./fonts/FoundersGrotesk-Regular.otf) format('opentype');
}

@font-face {
 font-family: 'Founder';
 font-weight: 500;
 src: url(./fonts/FoundersGrotesk-Semibold.otf) format('opentype');
}

@font-face {
 font-family: 'Founder ';
 font-weight: 900;
 src: url(./fonts/FoundersGrotesk-Bold.otf) format('opentype');
}
