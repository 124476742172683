@use "../../../styles/lib" as lib;

.toggleComponent{
 width: 100vw;
 display: flex;
 justify-content: center;
 margin: 40px auto;
}

.toogle{
 width: 80vw;
}

.directorsActive{
 width:40vw;
 background-color: lib.$primary;
 border: 2px solid lib.$primary;
// border-radius: 5px 0px 0px 5px;
padding: 10px 20px;
color: white;
cursor: pointer;
}
.directorsInactive{
 width:40vw;
 border: 2px solid lib.$primary;
// border-radius: 5px 0px 0px 5px;
padding: 10px 20px;
color: lib.$primary;
cursor: pointer;
}
.photographersActive{
 width:40vw;
 background-color: lib.$primary;
 border: 2px solid lib.$primary;
// border-radius: 0px 5px 5px 0px;
padding: 10px 20px;
color: white;
cursor: pointer;
}
.photographersInactive{
 width:40vw;
 border: 2px solid lib.$primary;
// border-radius: 0px 5px 5px 0px;
padding: 10px 20px;
color: lib.$primary;
cursor: pointer;
}

@media only screen and (min-width: 600px) {
 .directorsActive,.directorsInactive,.photographersActive,.photographersInactive{
  width: 300px;
 }
}