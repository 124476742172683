.loading-screen{
    position:fixed;
    color:white;
    background-color: black;
    height:100vh;
    width:100vw;
    z-index:900;
    top:0;
    left:0;

    img{
      opacity:0;
      animation: fade-in-out 1.5s linear 1;
    }
  }

  @keyframes fade-in-out {
      0%{opacity:1}
      50%{opacity:0}
      100%{opacity:1;}
    }

@media (min-width:768px){
  .loading-screen{
    img{
      transform: scale(1.4,1.4);
    }
  }
}