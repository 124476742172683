@use "../../../styles/lib" as lib;

.titleContainer{
  // width: 100vw;
  margin-top: 25px;
}
.title {
  font-size: 22px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: lib.$primary;
  font-family: 'Founder';
  font-weight: 900;
}
.hr {
  width: 215px;
  height: 9px;
  background-color: lib.$secondary;
  border: none;
  margin: 5px auto;
}

@media screen and (min-width:768px){
  .title {
  font-size: 32px;
}
}