@use "../../../styles/lib" as lib;

.pageTitle{
background-color: lib.$primary;
color: white;
height: 70px;
display: flex;
align-items: center;

}

.wrapper{
 display: grid;
 width: 100vw;
 // justify-content: space-evenly;
 align-items: center;
 padding: 10px 0;
 position: relative;
 // .back{
 //  width: 18px;
 //  height: 14px;
 //  cursor: pointer;
 // }
 h1{
  font-size: 22px;
  text-transform: uppercase;
  font-family: 'Founder';
  padding-top: 10px;
  text-align: center;
 }
 img{
   position: absolute;
   right: 2rem;
   cursor: pointer;
 }

 .back {
  left: 2rem;
 }
}