@use "../../../styles/lib" as lib;

.imgContainer{
 position: relative;
width: 100%;
height: 100%;
display:flex;

 .title{
  color: lib.$white;
  position: absolute;
  top: 10px;
  left: 10px;
 }
}

.carousel{
  height: calc((var(--std-length-di)/16)*9px);
 }

.img {
 width: 100%;
 height: calc((var(--std-length-di)/16)*9px);
 border-radius: 12px;
}

.leftButton,
.rightButton {
 border-radius: 50%;
 // padding: 2px 6px;
 background-color: transparent;
 border: 1px solid lib.$white;
 margin: auto 15px;
 cursor: pointer;
 font-size: 24px;
 font-weight: 900;
 color: lib.$white;
 // padding: 2px 10px 2px 8px;
}
.leftButton{
 padding: 2px 12px 2px 10px;
}
.rightButton{
 padding: 2px 10px 2px 12px;
 
}

.leftButton,.rightButton{
&:hover{
  background-color: white;
  // border-color: black;
  i{
   color: black;
  }
 }
}

@media only screen and (min-width: 700px) {
 .carousel{
  height: calc((var(--std-length-di)/16)*9px);
 }
 .img{
  height: calc((var(--std-length-di)/16)*9px);
  object-fit: contain;
 }
}
