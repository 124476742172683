body {
  margin: 0;
  // font-family: 'Founder',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Founder';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
  position:relative;
  overscroll-behavior: contain;
  overflow-x: hidden;

}

body::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: inherit;
  text-decoration: none;
}