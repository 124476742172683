@use "../../../styles/lib" as lib;
.viewMore{
 // width: 100vw;
 display: flex;
 justify-content: center;
 width: 100vw;
}
.viewMoreButton{
 width:50vw;
 height: 3.1em;
 font-size: 1em;
 color: lib.$primary;
 text-align: center;
 margin: 1.9em auto;
 cursor: pointer;
 //border: 0.125em solid lib.$primary;
 border: none;
 font-weight: bold;
 background-color: transparent;
}

.viewMoreButton:hover{
 color: white;
 background-color: lib.$primary;
 border: 0.125em solid white;
}

@media only screen and (min-width: 768px) {
 .viewMoreButton{
  width: 350px;
 }
}