.backdrop{
  position:fixed;
  background-color: black;
  height:100vh;
  width:100vw;
  z-index:100;
  top:0;
  left:0;

  #bd-close-btn{
    position:absolute;
    top:10%;
    right:2%;
    z-index:200;
    color:white;
    font-size: 2rem;
    cursor:pointer;
    z-index: 1001;
  }

  #bd-close-bg{
    position:absolute;
    width:100vw;
    height:100vh;
    z-index:100;
  }

  #bd-close:hover{
    color:black
  }

}